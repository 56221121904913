import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '@/assets/images/checked_in.png';
import _imports_1 from '@/assets/images/gift01.png';
import _imports_2 from '@/assets/images/gift.png';
import _imports_3 from '@/assets/images/sign_card.png';
const _hoisted_1 = {
  class: "sign-in"
};
const _hoisted_2 = {
  class: "sign-in__head"
};
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_4 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
const _hoisted_5 = {
  key: 2,
  src: _imports_2,
  alt: ""
};
const _hoisted_6 = {
  class: "sign-in__content"
};
const _hoisted_7 = {
  class: "sign-in__card"
};
const _hoisted_8 = {
  class: "sign-in__container"
};
const _hoisted_9 = {
  class: "sign-in__card--head"
};
const _hoisted_10 = {
  class: "sign-in__rules"
};
import { router } from '@/router';
import { SignIn, SignInData } from '@/api';
import { ref } from 'vue';
import { showLoadingToast, showToast } from 'vant';
export default {
  __name: 'SignIn',
  setup(__props) {
    const onClickLeft = () => {
      router.back();
    };
    const signInData = ref({
      num_day: 0
    });
    const _SignInData = () => {
      SignInData().then(({
        code,
        data
      }) => {
        if (code === 200) {
          signInData.value = data;
        }
      });
    };
    _SignInData();
    const _SignIn = () => {
      showLoadingToast();
      SignIn().then(({
        code,
        msg
      }) => {
        showToast(msg);
        if (code === 200) {
          _SignInData();
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        class: "nav-transparent__bg",
        fixed: "",
        "left-arrow": "",
        border: false,
        onClickLeft: onClickLeft
      }), _createElementVNode("div", _hoisted_2, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(7, item => {
        return _createElementVNode("div", {
          key: item
        }, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Day')) + " " + _toDisplayString(item), 1), signInData.value.num_day >= item ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : item === 7 ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : (_openBlock(), _createElementBlock("img", _hoisted_5))]);
      }), 64))]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_3,
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('DAILY SIGN-IN')), 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('Sign in for 7 consecutive days to receive additional rewards')), 1)]), _cache[1] || (_cache[1] = _createElementVNode("p", null, "+8000", -1))]), _createElementVNode("div", {
        class: "sign-in__btn",
        onClick: _cache[0] || (_cache[0] = $event => _SignIn())
      }, _toDisplayString(_ctx.$t('Sign-in')), 1)])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Sign-in rules')), 1), _createElementVNode("p", null, "1." + _toDisplayString(_ctx.$t('Complete 2 rounds of ratings on the same day, contact the receptionist for registration, complete the ratings to receive rewards, and start sign in now to receive more rewards.')), 1), _createElementVNode("p", null, "2." + _toDisplayString(_ctx.$t('The daily rating will be refreshed at 00:00 automatically by system.')), 1), _createElementVNode("p", null, "3." + _toDisplayString(_ctx.$t('Get different daily sign-in rewards!')), 1), _createElementVNode("p", null, "4." + _toDisplayString(_ctx.$t('Sign in for 7 consecutive days and get an extra ₹8,000.')), 1), _createElementVNode("p", null, "5." + _toDisplayString(_ctx.$t('Sign in for 3 consecutive days and get an extra ₹5,000.')), 1)])])]);
    };
  }
};